import baseComponents from "styles/theme/base-components"

const components = {
  ...baseComponents,
}

components.checkout = {
  ...components.checkout,
  shipMethod: {
    ...components.checkout.shipMethod,
    checkboxWrapper: { pl: "12px" },
  },
}
components.header = {
  ...components.header,
  navBar: {
    justifyContent: "center",
    px: 3,
  },
  navIcon: {
    color: "black",
    fontSize: "20px",
  },
}

export default components
